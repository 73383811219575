<template>
    <v-layout>
        <v-flex grow-shrink-0>{{ label }}</v-flex>
        <v-spacer/>
        <v-flex grow-shrink-0>
            <slot />
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: {
        label: String,
    }
}
</script>