<template>
<component :is="template">
    <template v-if="ready">
        <v-responsive v-if="ready && giftcard" width="100%" class="pb-4" v-intersect="{ handler: onIntersect, options: { threshold: [0, 1.0] } }">
            <v-card tile elevation="0">
                <v-card-text class="body-1">
                    <v-row>
                        <!-- 상품 이미지 -->
                        <v-col cols="12" md="6" class="pa-3 pa-md-6">
                            <v-layout wrap justify-center>
                                <v-responsive max-width="480" :aspect-ratio="1/1">
                                    <v-carousel height="auto" hide-delimiters show-arrows-on-hover :show-arrows="1 < giftcard.images.length">
                                        <v-carousel-item v-for="image, index in giftcard.images" :key="index">
                                            <v-img :src="image?.path || image?.src" width="100%" contain :aspect-ratio="1" />
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-responsive>
                            </v-layout>
                        </v-col>

                        <!-- 상품정보 -->
                        <v-col cols="12" md="6" class="pa-3 pa-md-6">
                            <v-responsive width="100%">

                                <!-- 제품명 -->
                                <template>
                                    <div class="headline primary--text">
                                        <p v-html="giftcard.name" />
                                    </div>
                                </template>

                                <!-- <v-divider class="my-4"/> -->

                                <!-- 판매가 -->
                                <!-- <template>
                                    <giftcard-info-field label="판매가" class="font-weight-bold">
                                        <span>권종 선택</span>
                                    </giftcard-info-field>
                                </template> -->

                                <v-divider class="my-3"/>

                                <!-- PC 수량 레이아웃 : 재고가 있을때 -->
                                <template v-if="!$vuetify.breakpoint.mobile && carts.length">
                                    <cart-list v-model="carts"/>
                                    <v-divider class="my-3"/>
                                    <v-layout class="py-2">
                                        <v-flex grow-shrink-0><span><b>총 합계</b></span></v-flex>
                                        <v-spacer/>
                                        <v-flex grow-shrink-0>
                                            <span><b>{{ parseInt(totalAmount).format() }}</b> 원</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="giftcard.commission.mobile" class="py-2">
                                        <v-flex grow-shrink-0>
                                            <span class="caption"><b>소액결제시 </b></span>
                                        </v-flex>
                                        <v-spacer/>
                                        <v-flex grow-shrink-0>
                                            <span class="caption"><b>{{ parseInt(totalAmount + commissionAmount).format() }}</b> 원</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider class="my-3"/>
                                    <v-layout class="mx-n1">
                                        <!-- <v-flex xs4 class="pa-1">
                                            <v-btn block tile x-large outlined @click="gift"><v-icon class="mr-4 ml-n2">{{ mdiGiftOutline }}</v-icon>선물하기</v-btn>
                                        </v-flex>
                                        <v-flex xs4 class="pa-1">
                                            <v-btn block tile dark x-large color="primary lighten-1" @click="addToCart"><v-icon class="mr-4 ml-n2">{{ mdiCartOutline }}</v-icon>장바구니</v-btn>
                                        </v-flex> -->
                                        <v-flex grow class="pa-1">
                                            <v-btn block dark x-large color="primary" elevation="0" @click="order"><v-icon class="mr-4 ml-n2">{{ mdiShoppingOutline }}</v-icon>구매하기</v-btn>
                                        </v-flex>
                                    </v-layout>
                                </template>
                            </v-responsive>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>


        </v-responsive>

        <!-- 인터섹팅 레이아웃 : 스크롤 아래로 내렸을때, 화면 상단에 도킹 -->
        <v-app-bar v-if="!isIntersecting" height="50" fixed color="white" elevation="1">
            <v-layout wrap justify-center>
                <v-responsive width="100%" max-width="1200">
                    <v-tabs v-model="intersectingIndex" show-arrows grow class="pa-0">
                        <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-content">상품정보</v-btn></v-tab>
                        <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-information">구매정보</v-btn></v-tab>
                    </v-tabs>
                </v-responsive>
            </v-layout>
        </v-app-bar>

        <!-- 상품 컨텐츠 -->
        <v-responsive>
            <v-card>
                <v-tabs v-model="intersectingIndex" show-arrows grow class="pa-0">
                    <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-content">상품정보</v-btn></v-tab>
                    <v-tab class="pa-0"><v-btn text block tile :ripple="false" height="100%" href="#giftcard-information">구매정보</v-btn></v-tab>
                </v-tabs>

                <v-divider />

                <div id="giftcard-content" class="mt-lg-4 pt-12"></div>

                <v-responsive v-intersect="{ handler: onDetails, options: { threshold: [0, 1.0] } }">
                    <v-card-text class="mt-4">
                        <v-img src="/images/giftcards/gift-guide.jpg" />
                        <p v-html="giftcard?.content" class="pa-lg-4 img-products-views"></p>
                    </v-card-text>
                </v-responsive>

                <div id="giftcard-information" class="mt-lg-4 pt-lg-12"></div>
                <v-responsive v-intersect="{ handler: onInformation, options: { threshold: [0, 1.0] } }" class="pa-3">
                    <v-row justify="center">
                        <v-col cols="auto">
                            <caption class="caption_hidden" style="display: inline-block; margin: 10px;padding: 0px;box-sizing: inherit;font-size: 18px;">교환/반품/품절안내</caption>
                        </v-col>
                    </v-row>
                    <template v-for="info, index in setting.informations">
                        <v-row :key="index">
                            <v-col cols="12" md="4" class="grey lighten-3 d-flex align-center justify-center">
                                <p v-html="info.term" class="text-center"/>
                            </v-col>
                            <v-col cols="12" md="8">
                                <p v-html="info.desc" />
                            </v-col>
                        </v-row>
                    </template>
                </v-responsive>
            </v-card>
            <login-dialog ref="loginDialog" />
        </v-responsive>
    </template>

    <!-- Mobile 하단 네비게이션 -->
    <template v-if="$vuetify.breakpoint.mobile && ready" slot="fab" slot-scope="{showScroll, scrollTo}">
        <v-app-bar app bottom height="auto" color="transparent" elevation="3">
            <v-responsive class="overflow-visible">
                <!-- Top 버튼 -->
                <!-- <v-btn v-show="showScroll" fab absolute top right x-large color="primary" class="mt-n14" @click="scrollTo(0,0)">
                    <div>
                        <v-icon class="mt-n2">{{ mdiChevronUp }}</v-icon>
                        <div class="body-2 mt-n1">top</div>
                    </div>
                </v-btn> -->

                <v-card>
                    <!-- <v-layout justify-center>
                        <v-btn block slot="extension" @click="expand = !expand" text large height="32" :ripple="false" active-class="no-active">
                            <v-icon v-show="!expand">{{ mdiChevronUp }}</v-icon>
                            <v-icon v-show="expand">{{ mdiChevronDown }}</v-icon>
                        </v-btn>
                    </v-layout> -->

                    <v-expand-transition>
                        <v-responsive elevation="0" class="buy-fixed">

                            <template v-if="carts.length">
                                <v-divider class="mb-4"/>
                                <cart-list v-model="carts" class="px-4"/>
                            </template>

                            <v-divider class="my-4"/>
                            <v-layout class="px-4 mb-4">
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2">총 합계</span>
                                </v-flex>
                                <v-spacer />
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2"><b class="primary--text">{{ parseInt(totalAmount).format() }}</b> 원</span>
                                </v-flex>
                            </v-layout>
                            <v-layout v-if="giftcard.commission.mobile" class="px-4 mb-4">
                                <v-flex grow-shrink-0>
                                    <span class="caption">소액결제시 </span>
                                </v-flex>
                                <v-spacer />
                                <v-flex grow-shrink-0>
                                    <span class="caption"><b class="primary--text">{{ parseInt(totalAmount + commissionAmount).format() }}</b> 원</span>
                                </v-flex>
                            </v-layout>

                            <v-divider class="mt-4 d-block d-lg-none"/>
                        </v-responsive>
                        <!-- <v-responsive v-show="expand" elevation="0"> -->

                            <!-- PC 수량 레이아웃 : 옵션상품 사용할때-->
                            <!-- <template v-if="giftcard.optionalProducts?.length">
                                <v-divider class="mb-4"/>
                                <optional-giftcards v-model="carts" :giftcard="giftcard" class="px-4" />
                            </template>

                            <template v-if="carts.length">
                                <v-divider class="my-4"/>
                                <cart-list v-model="carts" class="px-4"/>
                            </template>

                            <v-divider class="my-4"/>
                            <v-layout class="px-4 mb-4">
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2">총 합계</span>
                                </v-flex>
                                <v-spacer />
                                <v-flex grow-shrink-0>
                                    <span class="subtitle-2"><b class="primary--text">{{ parseInt(totalAmount).format() }}</b> 원</span>
                                </v-flex>
                            </v-layout> -->
                        <!-- </v-responsive> -->
                    </v-expand-transition>

                    <v-divider/>

                    <v-card-actions>
                        <v-layout class="py-1">
                            <!-- <v-flex grow-shrink-0 class="px-1">
                                <v-btn color="primary" min-width="auto" x-large class="px-4" outlined @click="addToCart"><v-icon>{{ mdiCartOutline }}</v-icon></v-btn>
                            </v-flex>
                            <v-flex grow-shrink-0 class="px-1">
                                <v-btn color="primary" min-width="auto" x-large class="px-4" outlined @click="gift"><v-icon>{{ mdiGiftOutline }}</v-icon></v-btn>
                            </v-flex> -->
                            <v-flex class="px-1">
                                <v-btn block color="primary" x-large @click="order"><span class="subtitle-2 white--text"><v-icon class="mr-2 ml-n4">{{ mdiShoppingOutline }}</v-icon>구매하기</span></v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-responsive>
        </v-app-bar>
    </template>

</component>
</template>
<script>
import api from "@/api";
import { mapState } from 'vuex';
// import { mdiChevronUp, mdiChevronDown, mdiChevronLeft, mdiChevronRight, mdiPlusCircleMultipleOutline, mdiCartOutline, mdiGiftOutline, mdiShoppingOutline } from "@mdi/js";
import { mdiChevronLeft, mdiChevronRight, mdiPlusCircleMultipleOutline, mdiCartOutline, mdiGiftOutline, mdiShoppingOutline } from "@mdi/js";

import LoginDialog from "@/components/client/login/login-dialog.vue";
import GiftcardInfoField from "@/components/client/gshop/giftcard/product-info-field.vue";
import CartList from "@/components/client/gshop/giftcard/cart-list.vue";

export default {
    components: {
        LoginDialog,
        GiftcardInfoField,
        CartList,
    },
    data(){
        return {
            // 아이콘
            mdiCartOutline,
            mdiGiftOutline,
            // mdiChevronUp,
            // mdiChevronDown,
            mdiChevronLeft,
            mdiChevronRight,
            mdiShoppingOutline,
            mdiPlusCircleMultipleOutline,

            ready: false,
            expand: false, // 모바일 하단 네비게이션 확장·축소

            giftcard: null,
            prevProduct: null,
            nextProduct: null,

            carts: [], // 수량

            setting: {
                informations: []
            },

            me: null,

            // 인터렉션 & 인터섹팅 데이터
            intersectingIndex: 0,
            intersecting: {
                isDetails: false,
                isInformation: false,
            },

            offsetTop : 0,
            showAppBar: false,
            isIntersecting: false,
            isScroll: false,
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){
            this.carts = [];

            let { giftcard } = await api.v1.gshop.giftcards.get({ _id: this.$attrs._giftcard });
            this.giftcard = giftcard;

            for(let option of giftcard?.options || []){
                this.carts.push({
                    _giftcard: giftcard._id,
                    _option: option._id,
                    giftcard,
                    name: option.name,
                    code: option.code || giftcard.code,
                    type: option.type,
                    thumb: giftcard.thumb,
                    platform: option.platform,
                    saleAmount: option.saleAmount,
                    salePrice: option.salePrice,
                    stock: option.stock,
                    soldout: option.soldout,
                    quantity: 0,
                });
            }

            if(this.accessToken){
                let { me } = await api.v1.me.profile.get();
                this.me = me;
            }

            let { setting } = await api.v1.gshop.setting.get();
            this.setting = setting;

            this.ready = true;
        },

        // 선물·구매·장바구니 클릭했을때, 검증
        validate(){
            try{
                if(!this.carts.find(cart => 0 < cart.quantity)) throw new Error("구매수량을 입력해주세요.");
                if(this.carts.find(cart => cart.soldout)) throw new Error("품절 처리된 상품 입니다.");

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },

        async login(){
            return new Promise((resolve, reject) => {
                if(this.logon) resolve();
                else{
                    this.$refs.loginDialog.$off();
                    this.$refs.loginDialog.$on("login", _ => resolve());
                    this.$refs.loginDialog.$on("cancel", _ => reject());
                    this.$refs.loginDialog.open();
                }
            });
        },
        async order() {
            if (this.validate()) {
                if (this.siteInfo.memberPayable && !this.siteInfo.nonmemberPayable) this.login().then(this.goOrder);
                else this.goOrder();
            }
        },
        async goOrder(){
            let { giftcard } = this;
            this.$store.dispatch("pickup", { carts: this.carts.filter(cart => !!cart.quantity).map(item => ({...item, giftcard})) });
            this.$router.push(`/gshop/order`);
        },
        complete(order){
            this.$refs.completeDialog.order = order;
            this.$refs.completeDialog.open();
        },

        ///////////////////////////////////////////////////////////////////////////////////
        // 인터렉션
        ///////////////////////////////////////////////////////////////////////////////////

        scrollTop(){
            window.scrollTo(0,0);
        },

        onIntersect (entries, observer) {
            if(this.isIntersecting != entries[0].isIntersecting) this.isIntersecting = entries[0].isIntersecting;
        },
        onDetails (entries, observer) {
            if(this.intersecting.isDetails != entries[0].isIntersecting) this.intersecting.isDetails = entries[0].isIntersecting;
        },
        onInformation (entries, observer) {
            if(this.intersecting.isInformation != entries[0].isIntersecting) this.intersecting.isInformation = entries[0].isIntersecting;
        },
        // onScroll (entries, observer) {
        //     // More information about these options
        //     // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        //     this.isScroll = entries[0].isIntersecting;
        // },
    },
    computed: {
        siteInfo(){ return this.$store.state.siteInfo },
        template(){ return () => import(`@/templates/${this.$theme.pages.gshop}`); },
        logon(){ return !!this.$store.state.accessToken; },
        logoff(){ return !this.$store.state.accessToken; },
        accessToken(){ return this.$store.state.accessToken },

        saleAmount(){
            return this.carts.reduce((saleAmount, cart) => {
                return saleAmount += cart.salePrice * cart.quantity;
            }, 0)
        },

        cutUnit(){
            return this.giftcard.commission?.cutUnit || 1;
        },
        commission(){
            return this.giftcard.commission?.mobile || 0;
        },
        commissionAmount(){
            if(!this.giftcard.commission.mobile) return 0;

            return this.carts.reduce((commissionAmount, cart) => {
                commissionAmount += Math.floor(cart.salePrice * this.commission / (1000 * this.cutUnit)) * cart.quantity * this.cutUnit * 10;
                return commissionAmount;
            }, 0);
        },
        totalAmount(){
            return this.saleAmount;
        }
    },
    watch: {
        quantity(){
            this.saleAmount = this.quantity * this.salePrice;
        },
        async accessToken(){
            if(this.accessToken){
                let { me } = await api.v1.me.profile.get();
                this.me = me;
            }
            else this.me = null;
        },

        carts: {
            handler(){
                for(let cart of this.carts){
                    let { platform, stock } = cart;
                    let quantity = parseInt(String(cart.quantity).replace(/[^0-9\-]/g, '') || 0);

                    if(this.siteInfo.orderType == `immediately` && [`excel`,`nanuri-excel`].includes(platform) && stock < quantity){
                        alert("재고가 부족합니다");
                        cart.quantity = stock;
                    }
                    else if(quantity < 0) cart.quantity = 0;
                    else cart.quantity = quantity;
                }
            },
            deep: true
        },

        // $route: {
        //     deep: true,
        //     handler(){
        //         this.ready = false;
        //         this.init();
        //     }
        // },

        "$route.params._giftcard"(){
            this.ready = false;
            this.init();
        },

        intersecting: {
            deep: true,
            handler(){
                if(this.intersecting.isDetails) this.intersectingIndex = 0;
                else if(this.intersecting.isInformation) this.intersectingIndex = 1;
            }
        }
    },
}
</script>

<style scoped>
.table_detail_guide th,
.table_detail_guide td{
    padding: 12px !important;
}

@media (max-width: 768px){
    .table_detail_guide{
        display: block
    }
    .table_detail_guide tr,
    .table_detail_guide th,
    .table_detail_guide td{
        display: block;
    }
    .table_detail_guide th,
    .table_detail_guide td{
        padding: 0 !important;
    }
    .table_detail_guide tr{
        margin-bottom: 8px !important;
    }
}

@media (max-width: 1024px){
    .buy-fixed{
        max-height: 200px;
        overflow-y: auto;
    }
}

.v-app-bar {
    height: auto !important;
}
.v-app-bar >>> .v-toolbar__content {
    padding: 0 !important;
    height: auto !important;
}
.v-number-field >>> input {
    text-align: center !important;
}
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
.v-card__text {
    color: black !important;
}
.giftcard-view .giftcard-name {
    font-size: 32px;
    font-weight: 500;
}
.giftcard-view .giftcard-description {
    font-size: 13px;
}
.giftcard-view .giftcard-content >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view .giftcard-notification >>> img {
    max-width: 100%;
    height: auto;
}
.giftcard-view >>> input {
    text-align: end;
}

.img-products-views >>> img {
    max-width:100%;
}

</style>