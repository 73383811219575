var render = function render(){
  var _vm$giftcard;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component",
    scopedSlots: _vm._u([{
      key: "fab",
      fn: function (_ref) {
        var showScroll = _ref.showScroll,
          scrollTo = _ref.scrollTo;
        return _vm.$vuetify.breakpoint.mobile && _vm.ready ? [_c('v-app-bar', {
          attrs: {
            "app": "",
            "bottom": "",
            "height": "auto",
            "color": "transparent",
            "elevation": "3"
          }
        }, [_c('v-responsive', {
          staticClass: "overflow-visible"
        }, [_c('v-card', [_c('v-expand-transition', [_c('v-responsive', {
          staticClass: "buy-fixed",
          attrs: {
            "elevation": "0"
          }
        }, [_vm.carts.length ? [_c('v-divider', {
          staticClass: "mb-4"
        }), _c('cart-list', {
          staticClass: "px-4",
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        })] : _vm._e(), _c('v-divider', {
          staticClass: "my-4"
        }), _c('v-layout', {
          staticClass: "px-4 mb-4"
        }, [_c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', {
          staticClass: "subtitle-2"
        }, [_vm._v("총 합계")])]), _c('v-spacer'), _c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', {
          staticClass: "subtitle-2"
        }, [_c('b', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(parseInt(_vm.totalAmount).format()))]), _vm._v(" 원")])])], 1), _vm.giftcard.commission.mobile ? _c('v-layout', {
          staticClass: "px-4 mb-4"
        }, [_c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', {
          staticClass: "caption"
        }, [_vm._v("소액결제시 ")])]), _c('v-spacer'), _c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', {
          staticClass: "caption"
        }, [_c('b', {
          staticClass: "primary--text"
        }, [_vm._v(_vm._s(parseInt(_vm.totalAmount + _vm.commissionAmount).format()))]), _vm._v(" 원")])])], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mt-4 d-block d-lg-none"
        })], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-layout', {
          staticClass: "py-1"
        }, [_c('v-flex', {
          staticClass: "px-1"
        }, [_c('v-btn', {
          attrs: {
            "block": "",
            "color": "primary",
            "x-large": ""
          },
          on: {
            "click": _vm.order
          }
        }, [_c('span', {
          staticClass: "subtitle-2 white--text"
        }, [_c('v-icon', {
          staticClass: "mr-2 ml-n4"
        }, [_vm._v(_vm._s(_vm.mdiShoppingOutline))]), _vm._v("구매하기")], 1)])], 1)], 1)], 1)], 1)], 1)], 1)] : undefined;
      }
    }], null, true)
  }, [_vm.ready ? [_vm.ready && _vm.giftcard ? _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onIntersect, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "pb-4",
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "body-1"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": "",
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "480",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-carousel', {
    attrs: {
      "height": "auto",
      "hide-delimiters": "",
      "show-arrows-on-hover": "",
      "show-arrows": 1 < _vm.giftcard.images.length
    }
  }, _vm._l(_vm.giftcard.images, function (image, index) {
    return _c('v-carousel-item', {
      key: index
    }, [_c('v-img', {
      attrs: {
        "src": (image === null || image === void 0 ? void 0 : image.path) || (image === null || image === void 0 ? void 0 : image.src),
        "width": "100%",
        "contain": "",
        "aspect-ratio": 1
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [[_c('div', {
    staticClass: "headline primary--text"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.giftcard.name)
    }
  })])], _c('v-divider', {
    staticClass: "my-3"
  }), !_vm.$vuetify.breakpoint.mobile && _vm.carts.length ? [_c('cart-list', {
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-layout', {
    staticClass: "py-2"
  }, [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', [_c('b', [_vm._v("총 합계")])])]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', [_c('b', [_vm._v(_vm._s(parseInt(_vm.totalAmount).format()))]), _vm._v(" 원")])])], 1), _vm.giftcard.commission.mobile ? _c('v-layout', {
    staticClass: "py-2"
  }, [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_c('b', [_vm._v("소액결제시 ")])])]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', {
    staticClass: "caption"
  }, [_c('b', [_vm._v(_vm._s(parseInt(_vm.totalAmount + _vm.commissionAmount).format()))]), _vm._v(" 원")])])], 1) : _vm._e(), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-layout', {
    staticClass: "mx-n1"
  }, [_c('v-flex', {
    staticClass: "pa-1",
    attrs: {
      "grow": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "dark": "",
      "x-large": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": _vm.order
    }
  }, [_c('v-icon', {
    staticClass: "mr-4 ml-n2"
  }, [_vm._v(_vm._s(_vm.mdiShoppingOutline))]), _vm._v("구매하기")], 1)], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1) : _vm._e(), !_vm.isIntersecting ? _c('v-app-bar', {
    attrs: {
      "height": "50",
      "fixed": "",
      "color": "white",
      "elevation": "1"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": "",
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "max-width": "1200"
    }
  }, [_c('v-tabs', {
    staticClass: "pa-0",
    attrs: {
      "show-arrows": "",
      "grow": ""
    },
    model: {
      value: _vm.intersectingIndex,
      callback: function ($$v) {
        _vm.intersectingIndex = $$v;
      },
      expression: "intersectingIndex"
    }
  }, [_c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-content"
    }
  }, [_vm._v("상품정보")])], 1), _c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-information"
    }
  }, [_vm._v("구매정보")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-responsive', [_c('v-card', [_c('v-tabs', {
    staticClass: "pa-0",
    attrs: {
      "show-arrows": "",
      "grow": ""
    },
    model: {
      value: _vm.intersectingIndex,
      callback: function ($$v) {
        _vm.intersectingIndex = $$v;
      },
      expression: "intersectingIndex"
    }
  }, [_c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-content"
    }
  }, [_vm._v("상품정보")])], 1), _c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-information"
    }
  }, [_vm._v("구매정보")])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "mt-lg-4 pt-12",
    attrs: {
      "id": "giftcard-content"
    }
  }), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onDetails,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onDetails, options: { threshold: [0, 1.0] } }"
    }]
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/giftcards/gift-guide.jpg"
    }
  }), _c('p', {
    staticClass: "pa-lg-4 img-products-views",
    domProps: {
      "innerHTML": _vm._s((_vm$giftcard = _vm.giftcard) === null || _vm$giftcard === void 0 ? void 0 : _vm$giftcard.content)
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-lg-4 pt-lg-12",
    attrs: {
      "id": "giftcard-information"
    }
  }), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onInformation,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onInformation, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "pa-3"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('caption', {
    staticClass: "caption_hidden",
    staticStyle: {
      "display": "inline-block",
      "margin": "10px",
      "padding": "0px",
      "box-sizing": "inherit",
      "font-size": "18px"
    }
  }, [_vm._v("교환/반품/품절안내")])])], 1), _vm._l(_vm.setting.informations, function (info, index) {
    return [_c('v-row', {
      key: index
    }, [_c('v-col', {
      staticClass: "grey lighten-3 d-flex align-center justify-center",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('p', {
      staticClass: "text-center",
      domProps: {
        "innerHTML": _vm._s(info.term)
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(info.desc)
      }
    })])], 1)];
  })], 2)], 1), _c('login-dialog', {
    ref: "loginDialog"
  })], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }