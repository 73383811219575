<template>
    <v-dialog v-model="show" max-width="480" >
        <v-card rounded="lg" class="member-login">
            <v-card-title>
                <v-row no-gutters class="py-2">
                    <v-col></v-col>
                    <v-col class="text-center"><span class="title">Login</span></v-col>
                    <v-col class="text-end"><v-icon large @click="cancel">mdi-close</v-icon></v-col>
                </v-row>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pa-8">
                <v-text-field v-model="username" label="아이디" persistent-placeholder hide-details />
                <v-password-field v-model="password" label="비밀번호" persistent-placeholder hide-details class="mt-4" @keydown.enter="login" />
                <v-btn width="100%" x-large dark color="primary" class="mt-4" @click="login">로그인</v-btn>
                <v-btn width="100%" x-large outlined class="mt-4" to="/join">회원가입</v-btn>
            </v-card-text>
            <v-divider/>
            <v-card-actions class="align-center px-6">
                <v-checkbox v-model="isRemember" label="아이디 저장" hide-details class="mt-0 pa-0"/>
                <v-spacer />
                <v-btn text tile to="/member/idpw">아이디/비밀번호 찾기</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import VPasswordField from "@/components/plugins/vuetify/v-password-field.vue";
export default {
    components: {
        VPasswordField
    },
    data(){
        return {
            show: false,

            username: null,
            password: null,
            isRemember: false,
        };
    },
    methods: {
        close(){
            this.show = false;
        },
        cancel(){
            this.close();
            this.$emit("cancel");
        },
        open(){
            this.show = true;
        },
        validate(){
            try{
                if(!this.username) throw new Error("아이디를 입력해주세요");
                if(!this.password) throw new Error("비밀번호를 등록해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        async login(){
            try{
                if(this.validate()){
                    await this.$store.dispatch("login", {
                        username: this.username,
                        password: this.password
                    });
    
                    if(this.isRemember) this.$cookies.set("username", this.username);
    
                    this.$emit("login");
                    this.close();
                }
            }
            catch(error){
                alert(error?.response?.data?.message ?? error.message);
            }
        }
    }
}
</script>
<style scoped>
.member-login .title {
    font-size: 24px !important;
}
.member-login .background-layout {
    position: absolute; width: 100%; height: 100%; left: 0; right: 0; top: 0; bottom: 0;
}
</style>