<template>
    <v-responsive>
        <template v-for="cart, index in carts">
            <cart-list-item v-model="carts[index]" :key="index" @remove="carts.splice(index, 1)" :class="{ 'mt-2' : 0 < index }" />
        </template>
    </v-responsive>
</template>
<script>
import CartListItem from "@/components/client/gshop/giftcard/cart-list-item.vue";
export default {
    components: {
        CartListItem
    },
    props: ["value"],
    data(){
        return {
            carts: this.$props.value
        }
    },
    watch: {
        carts: {
            deep: true,
            handler(){
                this.$emit("input", this.carts);
            }
        }
    }
}
</script>