<template>
    <v-layout align-center class="d-block d-md-flex">
        <v-flex grow-shrink-0 class="mb-2 mb-md-0">
            <span v-if="cart.type == 'giftcard'">본품</span>
            <template v-else>
                <span>{{ cart.name }}</span>
                <span v-if="siteInfo.orderType == `immediately` && [`excel`,`nanuri-excel`].includes(cart.platform)">{{ cart.stock && !cart.soldout ? `(${cart.stock}개)` : `(품절)` }}</span>
            </template>
        </v-flex>
        <v-spacer/>
        <v-flex grow-shrink-0 class="caption text-end">
            <v-layout style="width: 180px;" align-center>
                <v-btn min-width="36" color="primary" class="pa-0 mr-1" @click="cart.quantity--"><v-icon>mdi-minus</v-icon></v-btn>
                <v-text-field v-model="cart.quantity" height="20" outlined dense hide-details class="v-number-field" />
                <v-btn min-width="36" color="primary" class="pa-0 ml-1" @click="cart.quantity++"><v-icon>mdi-plus</v-icon></v-btn>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    props: ["value"],
    data(){
        return {
            cart: this.$props.value,
        }
    },
    computed: {
        siteInfo(){
            return this.$store.state.siteInfo;
        }
    },
    watch: {
        cart: {
            deep: true,
            handler(){
                this.$emit("input", this.cart);
            }
        }
    }
}
</script>