var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', [_vm._l(_vm.carts, function (cart, index) {
    return [_c('cart-list-item', {
      key: index,
      class: {
        'mt-2': 0 < index
      },
      on: {
        "remove": function ($event) {
          return _vm.carts.splice(index, 1);
        }
      },
      model: {
        value: _vm.carts[index],
        callback: function ($$v) {
          _vm.$set(_vm.carts, index, $$v);
        },
        expression: "carts[index]"
      }
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }